import React from 'react';
import TextInput from 'components/TextInput';
import styles from '@styles/ForgotPassword.module.scss';

const SignInForm: React.FC = () => (
  <form action="#" className={styles.form}>
    <div className={styles.form_field}>
      <TextInput
        id="e-mail"
        label="Seu e-mail:"
        placeholder="Digite seu e-mail"
        type="text"
      />
    </div>

    <div className={styles.form_inline_buttons}>
      <a
        href="/login"
        className={`${styles.customBtn} ${styles.text} ${styles.highlight_text}`}
      >
        Voltar
      </a>

      <span role="button" tabIndex={0} className={styles.actionBtn}>
        <span className={`${styles.text} ${styles.highlight_text}`}>Enviar</span>
      </span>
    </div>
  </form>
)

export default SignInForm;