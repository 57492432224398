import React from "react";
import Image from "next/image";

type AvaiableIcons =
  | "chevron-down"
  | "chevron-up"
  | "share"
  | "trophyIcon"
  | "greenTrophy"
  | "exclamation"
  | "alert";

interface IconProps {
  name: AvaiableIcons;
  action?: string;
  className?: string;
  height?: number;
}

const Icon: React.FC<IconProps> = ({
  className,
  name,
  action = "",
  height = 16,
}) => (
  <div className={className}>
    <Image
      src={`/assets/icons/${name}.svg`}
      alt={action}
      width={16}
      height={height}
    />
  </div>
);

export default Icon;
