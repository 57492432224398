import { GetServerSideProps } from 'next';

export const getServerSideProps: GetServerSideProps = async (context) => ({
  props: {},
});

export default function Startpage() {
  const iframeStyle = {
    height: '100vh',
    overflow: 'hidden',
  };
  return (
    <iframe title="Plusdin Membros" src="https://brius.com.br/membros/" style={iframeStyle} frameBorder="0" height="100%" width="100%" />
  );
}
