/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable jsx-a11y/anchor-is-valid */

import React, { useState, useEffect } from 'react';
import { GetStaticProps } from 'next';
import Image from 'next/image';
import { getSession } from 'next-auth/client';
import Router from 'next/router';

import LandingPageComponent, { LandingPageComponentProps } from 'components/LandingPageComponent';
import Content from 'src/infra/content';

const LandingPage: React.FC<LandingPageComponentProps> = (landingPageProps) => {
  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    getSession().then((session) => {
      if (session) {
        Router.replace('/home');
      } else {
        setIsLoading(false);
      }
    });
  }, []);

  if (isLoading) {
    return (
      <div className="loading">
        <Image src="/assets/icon-loading.svg" alt="loading" width={100} height={100} />
      </div>
    );
  }

  return <LandingPageComponent {...landingPageProps} />;
};

export const getStaticProps: GetStaticProps = async () => {
  const {
    menu,
    main,
    secondary,
    cards,
    last,
  } = await Content.fetchLandingPageContent();

  return {
    props: {
      menu,
      main,
      secondary,
      cards,
      last,
    },
    revalidate: 300
  };
};

export default LandingPage;
