import React, { useState, useEffect } from 'react';
import Image from 'next/image';
import { signOut, getSession } from 'next-auth/client';
import { isMobile } from 'react-device-detect';
import Link from 'next/link';

import MenuLateral from 'components/MenuLateral';
import Utils from "src/infra/utils";

import { LinkModel } from 'models/links';
import { AppName, LinksMenu } from 'infra/env-constants';

import styles from 'styles/Header.module.scss';

interface Props {
  links: LinkModel[];
  isSignedIn?: boolean;
}

const Header: React.FC<Props> = ({ links, isSignedIn = false }) => {
  const [username, setUsername] = useState("usuário");
  const [open, setOpen] = useState<boolean>(false);
  const onStateChange = (state: { isOpen: boolean }): void => {
    setOpen(state.isOpen);
  };
  const closeMenu = (): void => {
    setOpen(false);
  };

  useEffect(() => {
    async function readUsername() {
      const session = await getSession();
      if (session?.user?.name) {
        const name = session?.user?.name
        const splitted = name.trim().split(' ')
        const reduced = splitted[0].charAt(0) + splitted[1].charAt(0)
        setUsername(reduced);
      }
    }
    readUsername();
  }, []);

  const getUserName = () => {
    const name = Utils.formatName(username, true)

    return name.charAt(0) + name.charAt(1)
  }

  return (
    <header className={styles.header}>
      <a
        href="/home"
        key="logo"
        className={isMobile ? styles.logoMobile : styles.logo}
      >
        <Image src="/assets/logo.svg" alt={AppName} width={160} height="100%" />
      </a>

      <div className={styles.navigation}>
        <div className={styles.headerRightMobile}>
          {isMobile && (
            <a href="/convidar-amigos">
              <Image
                src="/assets/icons/share.svg"
                alt="perfil"
                width={24}
                height={24}
              />
            </a>
          )}
          {isMobile && (
            <MenuLateral
              links={LinksMenu}
              onStateChange={onStateChange}
              open={open}
              closeMenu={closeMenu}
              isSignedIn={isSignedIn}
            />
          )}
        </div>
        <div className={styles.headerRight}>
          {!isMobile
            && links.map((link) => (
              <Link key={link.id} href={link.path}>
                {link.name}
              </Link>
            ))}
          {!isMobile && (
            <a
              key="signOut"
              href="/"
              onClick={(e) => {
                e.preventDefault();
                signOut();
              }}
            >
              Sair
            </a>
          )}
          {!isMobile && (
            <Link href="editar-perfil">
              <span className={styles.headerAvatar}>
                {getUserName()}
              </span>
            </Link>
          )}
        </div>
      </div>
    </header>
  );
};

export default Header;
