import { useEffect, useState } from "react";
import { useRouter } from "next/router";
import { Provider, useSession } from "next-auth/client";
import Image from "next/image";
import Head from "next/head";

import Footer from "components/Footer";
import SignIn from "components/SignIn";
import Register from "components/Register";
import ForgotPassword from "components/ForgotPassword";
import Header from "components/Header";

import LandingPage from "pages/landing-page";
import StartPage from "pages/startpage";

import { LinksHeader, AppName, favicon } from "infra/env-constants";

import "@styles/globals.scss";

import "styles/Header.module.scss";
import "@styles/MenuLateral.css";
import UserBadgeProvider from "context/BadgesContext";
import User from "src/proxyClient/users";
import { getUserId } from "src/common/authUtil";

import TagManager from "react-gtm-module";

import cookieManager from "mixins/cookieManager";
import queryObjectToString from "mixins/queryObjectToString";

interface Props {
  pageProps: any;
  Component: any;
}

const isDevelopment = process.env.NEXT_PUBLIC_ENVIRONMENT === "development";

function MyApp({ Component, pageProps }: Props) {
  const router = useRouter();
  const [session, loading] = useSession();
  const [profile, setProfile] = useState(null);
  const [skipHeader, setSkipHeader] = useState(false);

  const pathToSkipHeaderAndFooter = ["/parceiros/emprestimo-sim/"];

  useEffect(() => {
    pathToSkipHeaderAndFooter.forEach(item => {
      if (router.route.includes(item)) {
        setSkipHeader(true);
      }
    })

    if (session) {
      const getProfile = async (session) => {
        return setProfile(await User.getUserProfileInfo(getUserId(session)));
      };

      const condition =
        profile === null ||
        cookieManager.getCookie("member_cookie_revalidate_profile");

      if (condition) {
        getProfile(session);
        cookieManager.eraseCookie("member_cookie_revalidate_profile");
      }
    }
  });

  const verifyEmail = (profile) => {
    if (router.pathname === "/verificar-email") return false;

    const excludeUrl = ["/", "/login", "/validar-email"];

    const verifyCondition =
      !profile.emailVerified && !excludeUrl.includes(router.pathname);

    if (verifyCondition) {
      router.push(`/verificar-email?${queryObjectToString(router.query)}`);

      return false;
    }

    return true;
  };

  const verifyUncompleteData = () => {
    const skipProfileVerifyPaths = [
      "/",
      "/login",
      "/completar-perfil",
      "/validar-email",
    ];

    const verifyCondition =
      profile && !skipProfileVerifyPaths.includes(router.pathname);

    const verifyProfile = (profile) => {
      const uncompleteCondition =
        !profile.cep || !profile.gender || !profile.birthDate;

      if (uncompleteCondition) {
        router.push({
          pathname: "/completar-perfil",
          query: { ...router.query },
        });
      }
    };

    if (verifyCondition) {
      verifyProfile(profile);
    }
  };
  useEffect(() => {
    if (profile) {
      const validEmailVerify = verifyEmail(profile);

      if (validEmailVerify) {
        verifyUncompleteData();
      }
    }
  });

  useEffect(() => {
    if (process.browser && process.env.NEXT_LOAD_ANALYTICS) {
      const tagManagerArgs = {
        gtmId: process.env.NEXT_PUBLIC_TAG_MANAGER,
        dataLayer: {
          page: {
            template: "area-membros",
          },
        },
      };

      TagManager.initialize(tagManagerArgs);
    }
  }, []);

  if (loading) {
    return (
      <div className="loading">
        <Image
          src="/assets/icon-loading.svg"
          alt="loading"
          width={100}
          height={100}
        />
      </div>
    );
  }

  if (!session && !isDevelopment) {
    if (router.pathname === "/") {
      return <StartPage {...pageProps} />;
    }
    if (router.pathname === "/login") {
      return <SignIn />;
    }
    if (router.pathname === "/cadastro") {
      return <Register />;
    }
    if (router.pathname === "/esqueci-minha-senha") {
      return <ForgotPassword />;
    }
    if (router.pathname === "/landing-page") {
      return <LandingPage {...pageProps} />;
    }
    window.location.href = `/login?${queryObjectToString(router.query)}`;

    // A última linha evita que a página de conteúdo seja mostrado por uma fração de segundo
    return <SignIn />;
  }

  return (
    <div id="root">
      <Head>
        <title>{AppName}</title>
        <link rel="shortcut icon" href={favicon} />
      </Head>
      {!skipHeader && <Header isSignedIn links={LinksHeader} />}

      <UserBadgeProvider>
        <Provider session={pageProps.session}>
          <main>
            <Component {...pageProps} />
          </main>
        </Provider>
      </UserBadgeProvider>
      {!pathToSkipHeaderAndFooter.map((path) =>
        path.includes(router.route)
      ) && <Footer />}
    </div>
  );
}

export default MyApp;
