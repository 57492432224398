import { STATUS_CODE } from "src/common/httpUtil";
import {
  CourseProgressStatusEnum,
  ModuleProgressStatusEnum,
  Smiles,
  VideoProgressStatusEnum,
} from "../../models/enums";

import client from "./client";
import msgOps from "./msgOps";
import offers from "./offers";

type ResponseStatusCode = number;

const USER_PRE_APPROVAL_ENDPOINT = (
  offerProvider: string,
  userCpf: string
): string =>
  `/postback/${offerProvider}/v1/customers/${userCpf}/check?product=click`;
const USERS_PROFILE_INFO_ENDPOINT = (userId: string): string =>
  `/users/${userId}/profile-info`;
const USERS_VALIDATE_EMAIL_ENDPOINT = (): string => `/users/validate-email`;
const USERS_FRIEND_INVITE_ENDPOINT = (userId: string): string =>
  `/users/${userId}/friend-invite`;
const USERS_FRIEND_INVITE_HASH = (userId: string, userHash: string): string =>
  `/users/${userId}/friend-invite/${userHash}`;
const USERS_COURSES_ENDPOINT = (userId: string): string =>
  `/users/${userId}/progress/courses`;
const USERS_COURSE_PROGRESS_ENDPOINT = (
  userId: string,
  courseId: number
): string => `/users/${userId}/progress/courses/${courseId}`;
const USER_COURSE_FEEDBACK_INFO_ENDPOINT = (
  userId: string,
  courseId: number
): string => `/users/${userId}/courses/${courseId}/feedback`;
const USER_VIDEO_TIME_ENDPOINT = (
  userId: string,
  videoId: number | string,
  seconds: number
): string => `/users/${userId}/videos/${videoId}/progress/seconds/${seconds}`;
const USER_VIDEO_STATUS_ENDPOINT = (
  userId: string,
  videoId: number | string,
  status: number
): string => `/users/${userId}/videos/${videoId}/progress/status/${status}`;
const USER_BADGES_INFO = (userId: string): string => `/users/${userId}/badges`;
const USER_BADGE_SLUG_INFO = (userId: string, badgeSlug: string): string =>
  `/users/${userId}/badges/${badgeSlug}`;
const USERS_RESEND_VALIDATE_EMAIL_ENDPOINT = (userId: string): string =>
  `/users/${userId}/resend-validate-email`;
const USERS_SEND_LEAD_RETARGETING_ENDPOINT = (): string =>
  `/services/lead-conception-retargeting`;

export interface SocialMedia {
  facebookUrl?: string;
  instagramUsername?: string;
}

export interface UserProfile {
  id?: string;
  name: string;
  email: string;
  cpf?: string;
  cellphone?: string;
  cep?: string;
  socialMedia?: SocialMedia;
  gender?: string;
  birthDate?: string;
}

export interface Contact {
  email: string;
  phone: string;
  hashedEmail: string;
  firstName: string;
  lastName: string;
  audienceName: string;
}

export interface RetargetingPayload {
  contact: Contact;
  listId: string;
  startDate: Date;
}

export interface Cards {
  bandeira: string;
  variante: string;
  valorLimiteVariante: number;
  valorAnuidadeTitular: number;
  valorAnuidadeAdicional: number;
  nomeProduto: string;
  tarifaOverlimit: number;
  tarifaSMS: number;
  diaVencimento: Array<number>;
}

export interface UserApprovalResult {
  statusProposta?: string;
  codigoStatusProposta?: number;
  dadosPessoais?: Object;
  cartoes?: Array<Cards>;
}

export interface BadgesInfo {
  id: number;
  seen: boolean;
  dateOfAcquisition: Date;
}

export interface VideoProgress {
  videoId: number;
  status: VideoProgressStatusEnum;
  inSeconds: number;
  lengthInSeconds: number;
}

export interface ModuleProgress {
  moduleId: number;
  progressPercentage: number;
  status: ModuleProgressStatusEnum;
  videosProgress: VideoProgress[];
}

export interface CourseProgress {
  courseId: number;
  modulesProgress: ModuleProgress[];
  progressInSeconds: number;
  progressPercentage: number;
  status: CourseProgressStatusEnum;
  nextVideo: {
    moduleId: string | number | null;
    videoId: string | number | null;
  };
}

export interface FeedbackInfo {
  courseIsFinished: boolean;
  feedbackWasGiven: boolean;
}

const checkUserPreApproval = async (
  offerProvider: string,
  userCpf: string
): Promise<UserApprovalResult> => {
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${process.env.NEXT_PUBLIC_OFFERS_BEARER_TOKEN}`,
      },
    };

    const userProfile = await offers.get(
      USER_PRE_APPROVAL_ENDPOINT(offerProvider, userCpf),
      config
    );
    return userProfile.data;
  } catch (err) {
    return {} as UserApprovalResult;
  }
};

const getUserProfileInfo = async (userId: string): Promise<UserProfile> => {
  try {
    const userProfile = await client.get<UserProfile>(
      USERS_PROFILE_INFO_ENDPOINT(userId)
    );
    return userProfile.data;
  } catch (err) {
    return {} as UserProfile;
  }
};

const getUserFriendInviteHash = async (userId: string): Promise<String> => {
  try {
    const userFriendInvite = await client.get<String>(
      USERS_FRIEND_INVITE_ENDPOINT(userId)
    );
    return userFriendInvite.data;
  } catch (err) {
    return "";
  }
};

const sendLeadRetargeting = async (payload: RetargetingPayload) => {
  try {
    const inviteSent = await msgOps.post<String>(
      USERS_SEND_LEAD_RETARGETING_ENDPOINT(),
      payload
    );

    return inviteSent.status;
  } catch (err) {
    return "";
  }
};

const sendUserFriendInviteByEmail = async (
  userId: string,
  email: string,
  directInviteLink: string
) => {
  try {
    const inviteSent = await client.post<String>(
      USERS_FRIEND_INVITE_ENDPOINT(userId),
      {
        email,
        inviteLink: directInviteLink,
      }
    );

    return inviteSent.status;
  } catch (err) {
    return "";
  }
};

const setUserFriendInviteBadge = async (
  userId: string,
  userHash: string
): Promise<number> => {
  try {
    const userBadge = await client.post<String>(
      USERS_FRIEND_INVITE_HASH(userId, userHash)
    );
    return userBadge.status;
  } catch (err) {
    return STATUS_CODE.BAD_REQUEST;
  }
};

const saveUserProfileInfo = async (
  userId: string,
  userProfile: UserProfile
): Promise<void> => {
  try {
    await client.patch(USERS_PROFILE_INFO_ENDPOINT(userId), userProfile);
  } catch (err) {
    /* eslint-disable no-console */
    console.error(err);
  }
};

const getUserCourses = async (userId: string): Promise<CourseProgress[]> => {
  try {
    const courses = await client.get<CourseProgress[]>(
      USERS_COURSES_ENDPOINT(userId)
    );
    return courses.data;
  } catch (err) {
    return [];
  }
};

const getUserCourseProgress = async (
  userId: string,
  courseId: number
): Promise<CourseProgress> => {
  try {
    const course = await client.get<CourseProgress>(
      USERS_COURSE_PROGRESS_ENDPOINT(userId, courseId)
    );
    return course.data;
  } catch (err) {
    return {} as CourseProgress;
  }
};

const saveUserVideoStatus = async (
  userId: string,
  videoId: number | string,
  status: VideoProgressStatusEnum
): Promise<ResponseStatusCode> => {
  try {
    const response = await client.put(
      USER_VIDEO_STATUS_ENDPOINT(userId, videoId, status)
    );
    return response.status;
  } catch (err) {
    return err;
  }
};

const saveUserVideoTime = async (
  userId: string,
  videoId: number | string,
  videoProgressInSeconds: number
): Promise<ResponseStatusCode> => {
  try {
    const response = await client.put(
      USER_VIDEO_TIME_ENDPOINT(userId, videoId, videoProgressInSeconds)
    );
    return response.status;
  } catch (err) {
    return err;
  }
};

const getUserCourseFeedbackInfo = async (
  userId: string,
  courseId: number
): Promise<FeedbackInfo> => {
  try {
    const feedbackInfo = await client.get<FeedbackInfo>(
      USER_COURSE_FEEDBACK_INFO_ENDPOINT(userId, courseId)
    );
    return feedbackInfo.data;
  } catch (err) {
    return {} as FeedbackInfo;
  }
};

const postUserCourseFeedback = async (
  userId: string,
  courseId: number,
  comment: string,
  score: Smiles
): Promise<void> => {
  try {
    await client.post<void>(
      USER_COURSE_FEEDBACK_INFO_ENDPOINT(userId, courseId),
      { comment, score }
    );
  } catch (err) {
    // eslint-disable-next-line no-console
    console.error(err);
  }
};

const getUserBadges = async (userId: string): Promise<BadgesInfo[]> => {
  try {
    const userBadgesInfo = await client.get<BadgesInfo[]>(
      USER_BADGES_INFO(userId)
    );
    return userBadgesInfo.data;
  } catch (err) {
    return [] as BadgesInfo[];
  }
};

const createUserBadges = async (
  userId: string,
  trophySlug: string
): Promise<void> => {
  try {
    await client.post<void>(USER_BADGE_SLUG_INFO(userId, trophySlug));
  } catch (err) {
    console.error(err.message);
  }
};

const updateNotificationsToSeen = async (userId: string): Promise<void> => {
  try {
    await client.patch(USER_BADGES_INFO(userId));
  } catch (err) {
    console.log(err.message);
  }
};

const deleteCourseProgress = async (
  userId: string,
  courseId: string
): Promise<void> => {
  try {
    await client.delete(
      USERS_COURSE_PROGRESS_ENDPOINT(userId, parseInt(courseId, 10))
    );
  } catch (err) {
    console.error(err.message);
  }
};

const reSendValidateEmail = async (
  userId: string,
  link: string
): Promise<number> => {
  try {
    const response = await client.post(
      process.env.NEXT_PUBLIC_API_URL +
        USERS_RESEND_VALIDATE_EMAIL_ENDPOINT(userId),
      { link }
    );

    return response.status;
  } catch (err) {
    console.log(err.message);
  }
};

const validateUserEmail = async (validateHash: string): Promise<any> => {
  try {
    const response = await client.post(
      process.env.NEXT_PUBLIC_API_URL + USERS_VALIDATE_EMAIL_ENDPOINT(),
      { validateHash }
    );

    return response.status;
  } catch (err) {
    console.error(err.message);
  }
};

export default {
  checkUserPreApproval,
  createUserBadges,
  getUserProfileInfo,
  getUserCourses,
  getUserCourseProgress,
  getUserFriendInviteHash,
  setUserFriendInviteBadge,
  saveUserProfileInfo,
  saveUserVideoTime,
  saveUserVideoStatus,
  sendLeadRetargeting,
  sendUserFriendInviteByEmail,
  getUserCourseFeedbackInfo,
  getUserBadges,
  updateNotificationsToSeen,
  postUserCourseFeedback,
  deleteCourseProgress,
  validateUserEmail,
  reSendValidateEmail,
};
