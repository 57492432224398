import React from "react";

import Icon from "components/Icon";

import Utils from "src/infra/utils";

import styles from "../styles/TextInput.module.scss";

export interface TextInputProps {
  id: string;
  containerClass?: string;
  inputContainerClass?: string;
  inputClass?: string;
  disabled?: boolean;
  type?: React.HTMLInputTypeAttribute;
  label?: string;
  value?: string;
  placeholder?: string;
  readOnly?: boolean;
  error?: string;
  inputMode?: "numeric" | "none" | "text";
  ref?: any;
  maxLength?: number;
  minLength?: number;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

const TextInput: React.FC<TextInputProps> = ({
  id,
  containerClass = "",
  inputContainerClass = "",
  inputClass = "",
  disabled = false,
  error = null,
  inputMode = "text",
  label,
  placeholder,
  readOnly = false,
  type = "text",
  value,
  maxLength = 524288,
  minLength = 0,
  onChange,
}) => (
  <div className={`${styles.textInputContainer} ${containerClass}`}>
    {label && (
      <label className={styles.label} htmlFor={id}>
        {label}
      </label>
    )}
    <div
      className={`${Utils.combineStyles(
        styles.fieldWrapper,
        error && styles.errorField
      )}  ${inputContainerClass}`}
    >
      <input
        className={`${styles.input} ${inputClass}`}
        id={id}
        disabled={disabled}
        value={value}
        type={type}
        readOnly={readOnly}
        placeholder={placeholder}
        inputMode={inputMode}
        onChange={onChange}
        maxLength={maxLength}
        minLength={minLength}
      />
      {error && <Icon className={styles.errorIcon} name="exclamation" />}
    </div>
    <i className={styles.errorMessage}>{error}</i>
  </div>
);

export default TextInput;
