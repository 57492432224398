import React, { useEffect, useState }  from "react";
import styles from "@styles/SignIn.module.scss";
import { signIn } from "next-auth/client";
import { AppName } from "../infra/env-constants";
import { set } from "idb-keyval";
import cookieCutter from 'cookie-cutter';
import AdvisedEmailModal from "./AdvisedEmailModal";

import SignInForm from 'components/SignInForm';

const SignIn: React.FC = () => {
const [showGoogleLogin, setShowGoogleLogin] = useState(false);
const message = "Para acessar:";

useEffect(() => {
    const webview = /(Version\/\d+.*\/\d+.0.0.0 Mobile|; ?wv|(iPhone|iPod|iPad).*AppleWebKit(?!.*Safari))/i.test(navigator.userAgent);

    if (!webview) {
        setShowGoogleLogin(true);
    }
})

const tryToLogin = (e, authProviderName) => {
    e.preventDefault();

    const urlParams = new URLSearchParams(window.location.search);
    const inviteOrigin = urlParams.get("invite-origin");
    const drawingOrigin = urlParams.get("drawing-hash");

    if (inviteOrigin) {
        set("members_invite_origin", inviteOrigin)
            .then(() => console.log("defined store collection"))
            .catch((err) => console.error("", err));
    }

    if (drawingOrigin) {
        set("members_invite_drawing", drawingOrigin)
            .then(() => console.log("defined store collection"))
            .catch((err) => console.error("", err));
    }

    var now = new Date();
    var time = now.getTime();
    var expireTime = time + 1000 * 1000;
    now.setTime(expireTime);

    const cookieOptions = {
        domain: process.env.NEXT_PUBLIC_COOKIE_DOMAIN,
        expires: now.toUTCString()
    }

    cookieCutter.set('auth_members_provider', authProviderName, cookieOptions);

    setTimeout(() => {
        signIn("identity-server4");
    }, 500)
};

return (
    <div id="signIn" className={styles.container}>
        <AdvisedEmailModal/>
        <img
            src="/assets/logo.svg"
            alt={AppName}
            width={171}
            height={73}
            className={styles.logo}
        />
        <div className={styles.card}>
            <h3 className={styles.title}>{message}</h3>
            {
                showGoogleLogin &&
                (
                    <span
                        role="button"
                        tabIndex={0}
                        onKeyDown={(e) => tryToLogin(e, 'Google')}
                        className={styles.customBtn}
                        onClick={(e) => tryToLogin(e, 'Google')}
                    >
                        <img
                            className={styles.icon}
                            src="/assets/icon-google.svg"
                            alt={AppName}
                            width={16}
                            height={16}
                        />{" "}
                        <span className={styles.text}>Entrar com Google</span>
                    </span>
                )
            }
            <span
                role="button"
                tabIndex={0}
                onKeyDown={(e) => tryToLogin(e, 'Facebook')}
                className={styles.customBtn}
                onClick={(e) => tryToLogin(e, 'Facebook')}
            >
                <img
                    className={styles.icon}
                    src="/assets/icon-facebook-blue.svg"
                    alt={AppName}
                    width={16}
                    height={16}
                />{" "}
                <span className={styles.text}>Entrar com Facebook</span>
            </span>

            {/* <a
            href="/cadastro"
            className={`${styles.text} ${styles.underline_text}`}
            >
            Cadastre-se
            </a>
        
            <div className={styles.separator}>
            <span className={styles.line}></span>
            <p>ou</p>
            <span className={styles.line}></span>
            </div>

            <SignInForm /> */}
        </div>

        <div className={styles.ellipsis} />
    </div>
);
};

export default SignIn;